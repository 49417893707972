import React, { useState } from "react"
import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../styles/privacy.module.scss"
import * as button from "../styles/buttons.module.scss"

const Privacy = () => {
  const [language, setLanguage] = useState(false)

  return (
    <Layout>
      <Seo title="Adatvédelem - Privacy Policy" />
      <section id={styles.container}>
        <header id={styles.header}>
          <h1>Adatvédelem - Privacy Policy</h1>
        </header>

        <button id={styles.language} onClick={() => setLanguage(!language)}>
          English/Magyar
        </button>

        <div id={styles.containerInfo}>
          {language === false ? (
            <div>
              <div id={styles.text}>
                This privacy policy will explain how our organization - NETALON
                Rendszerek Kft. (Tax number: HU12824483) - uses the personal
                data we collect from you when you use our website.
                <br />
                <br />
                <h2>Topics:</h2>
                <ul>
                  <li>What data do we collect?</li>
                  <li>How do we collect your data?</li>
                  <li>How will we use your data?</li>
                  <li>How do we store your data?</li>
                  <li>Marketing </li>
                  <li>What are your data protection rights?</li>
                  <li>What are cookies?</li>
                  <li>What types of cookies do we use?</li>
                  <li>Privacy policies of other websites</li>
                  <li>Changes to our privacy policy</li>
                  <li> How to contact us</li>
                  <li>How to contact the appropriate authorities</li>
                  <li>What data do we collect?</li>
                </ul>
                <h2>Our Company collects the following data:</h2>
                <ul>
                  <li>
                    personal identification information (firstname, lastname,
                    email address),
                  </li>
                  <li>your message or question you send to us.</li>
                </ul>
                <h2> How do we collect your data?</h2>
                You directly provide Our Company with most of the data we
                collect. We collect data and process data when you:
                <li>
                  send an email to us using our contact form or an email client,
                </li>
                <li>
                  access the calendar information on our site by clicking the
                  "Calendar" menu. In this case you need to use your gmail
                  creditentals to access the Calendar data.
                </li>
                <h2>How will we use your data?</h2>
                We collect your data so that we can:
                <li>reply to your questions by email,</li>
                <li>
                  email you with offers and special services we think you might
                  like,
                </li>
                <li>
                  inform you about the available free times in order you be able
                  book a one hour personal training session.
                </li>
                <h2>How do we store your data?</h2>We securely stores your data
                at Herceghalom, Búzavirág utca 22. on our computer. We protect
                your data with Bitdefender Total Scuerity software. We will keep
                your email, firtsname and lastname on our computer for 5 years.
                Once this time period has expired, we will delete your data.
                <h2>Marketing</h2>
                Our Company would like to send you information about products,
                offers and services related to healthy- foods and lifestyle that
                we think you might like. If you have agreed to receive this kind
                of marketing information, you may always opt out at a later
                date. You have the right at any time to stop Our Company from
                contacting you for marketing purposes or giving your data to
                other members of the Our Company Group. If you no longer wish to
                be contacted for marketing purposes, please send us an email by
                the subject: unsuscribe.
                <h2>What are your data protection rights?</h2>
                Our Company would like to make sure you are fully aware of all
                of your data protection rights. Every user is entitled to the
                following:
                <li>
                  <b>The right to access </b>– You have the right to request Our
                  Company for copies of your personal data. We may charge you a
                  small fee for this service.
                </li>
                <li>
                  <b>The right to rectification</b> – You have the right to
                  request that Our Company correct any information you believe
                  is inaccurate. You also have the right to request Our Company
                  to complete the information you believe is incomplete.
                </li>
                <li>
                  <b>The right to erasure </b>– You have the right to request
                  that Our Company erase your personal data, under certain
                  conditions.
                </li>
                <li>
                  <b>The right to restrict processing</b> – You have the right
                  to request that Our Company restrict the processing of your
                  personal data, under certain conditions.
                </li>
                <li>
                  <b>The right to object to processing</b> – You have the right
                  to object to Our Company’s processing of your personal data,
                  under certain conditions.
                </li>
                <li>
                  <b>The right to data portability</b> – You have the right to
                  request that Our Company transfer the data that we have
                  collected to another organization, or directly to you, under
                  certain conditions.
                </li>
                <li>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us:
                </li>
                <ul>
                  <li>
                    at our email: <b>kovacsg76@gmail.com</b>
                  </li>
                  <li>call us at: +36-20-9822-266 </li>
                  <li>write to us: 2053 Herceghalom, Búzavirág utca 22.</li>
                </ul>
                <h2>Cookies</h2>
                Cookies are text files placed on your computer to collect
                standard Internet log information and visitor behavior
                information.
                <h2>What types of cookies do we use?</h2>
                We don't use cookies.
                <h2>Privacy policies of other websites</h2>
                Our Company website may contain links to other websites. Our
                privacy policy applies only to our website, so if you click on a
                link to another website, you should read their privacy policy.
                <h2>Changes to our privacy policy</h2>
                Our Company keeps its privacy policy under regular review and
                places any updates on this web page. This privacy policy was
                last updated on 18th November 2021.
                <h2>How to contact us</h2>
                If you have any questions about Our Company’s privacy policy,
                the data we hold on you, or you would like to exercise one of
                your data protection rights, please do not hesitate to contact
                us:
                <ul>
                  <li> contact person: Gabriella Kovács</li>
                  <li> email us at: kovacsg76@gmail.com</li>
                  <li>call us: +36-20-9822-266</li>
                  <li>write to us at: 2053 Herceghalom, Búzavirág utca 22.</li>
                </ul>
                <h2>How to contact the appropriate authority</h2>
                Should you wish to report a complaint or if you feel that Our
                Company has not addressed your concern in a satisfactory manner,
                you may contact the Information Commissioner’s Office:
                <li></li>
                <li>Address: 1125 Budapest, Szilágyi Erzsébet fasor 22/C</li>
                <li>PO: 1530 Budapest, Postafiók: 5.</li>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <div id={styles.text}>
                Ez az adatvédelmi szabályzat bemutatja, hogy társaságunk, a
                NETALON Rendszerek Kft. (Adószám: 12824483-1-13) hogyan
                használja fel az Ön személyes adatait, amikor ezt a weboldalt
                meglátogatja.
                <br />
                <br />
                <h2>Tartalomjegyzék:</h2>
                <ul>
                  <li>Milyen adatokat gyűjtünk?</li>
                  <li>Hogyan gyűjtjük az Ön adatait?</li>
                  <li>Hogyan használjuk fek az Ön adatait?</li>
                  <li>Hogyan tároljuk az Ön adatait?</li>
                  <li>Marketing </li>
                  <li>Mik az Ön adatvédelmi jogai?</li>
                  <li>Mik a cookie-k?</li>
                  <li>Milyen típusú cookie-kat hasznáunk?</li>

                  <li>Más weboldalak adatvédelmi szabályzata</li>
                  <li>Adatvédelmi szabályzat változtatása</li>

                  <li>Hogyan léphet kapcsolatba velünk</li>
                  <li>Hogyan léphet kapcsolatba a hatóságokkal</li>
                  <li>Milyen adatokat gyűjtünk?</li>
                </ul>
                <h2>Társaságunk a következő adatokat gyűjti:</h2>
                <ul>
                  <li>
                    személyes azonosításra alkalmas adatok (keresztnév,
                    vezetéknév, email cím),
                  </li>
                  <li>az Ön által részünkre küldött üzenet vagy kérdés.</li>
                </ul>
                <h2> Hogyan gyűjtjük az adatokat?</h2>
                Ön közvetlenül adja meg az adatokat, amikor:
                <li>
                  emailt küld nekünk az oldalon található email formanyomtatvány
                  kiküldésével vagy az Ön email kliensének a használatával,
                </li>
                <li>
                  hozzáfér a foglaltsági adatokhoz a Naptár funkció
                  használatával a Naptár menüre kattintva. Ilyenkor az adatok
                  eléréséhez szükség van az Ön hitelesítésére, amely gmail
                  adatainak megadásával történik.
                </li>
                <h2>Hogyan használjuk az Ön adatait?</h2>
                Azért gyűjtjük az Ön adatait, hogy:
                <li>
                  emailben válaszolni tudjunk az Ön által feltett kérdésekre,
                </li>
                <li>
                  emailben ajánlani tudjunk olyan szolgáltatásokat, amelyekről
                  azt gondoljuk, hogy érdekelhetik,
                </li>
                <li>
                  a Naptár funkción keresztül tájékoztatni tudjuk azokról a
                  szabad időpontokról amikor igénybe veheti a
                  szolgáltatásainkat.
                </li>
                <h2>Hogyan tároljuk az adatait?</h2>Az adatait biztonságban, a
                Herceghalom, Búzavirág utca 22. című székhelyünkön tároljuk egy
                számítógépen. Az adatait a Bitdefender Total Scuerity software
                védelmezi. email címét, keresztnevét és vezetéknevét 5 évi
                tároljuk. Ezt követően az adatai törlésre kerülnek.
                <h2>Marketing</h2>
                Társaságunk szeretne Önnek emailben egészséges táplálkozással és
                életmóddal kapcsolatos termékekről és szolgáltatásokról
                ajánlatokat küldeni. Ha beleegyezett ezeknek az emaileknek a
                fogadásába, azokról bármikor leiratkozhat. Önnek joga van ahhoz,
                hogy megtiltsa számunkra ilyen marketing célú emailek küldését.
                Ha a továbbiakban nem szeretne ilyen tartalmú leveleket fogadni
                tőlünk, kérjük küldjön el a részünkre egy emailt a "leiratkozom"
                tárgyú tartalommal.
                <h2>Mik az Ön adatvédelmi jogai?</h2>
                Társaságunk szeretne megbizonyosodni arról, hogy Ön tisztában
                van az adatvédelmi jogaival. Az oldalunk használatakor Önnek,
                mint felhasználónak az alábbi jogai vannak:
                <li>
                  <b>Hozzáférés </b>– Kérhet minket, hogy küldjük el Önnek a
                  nálunk tárolt adatainak egy másolatát.
                </li>
                <li>
                  <b>Helyesbítés</b> – Kérhet minket, hogy az Ön által
                  pontatlannak vélt adatokat javítsuk ki vagy a hiányos
                  információkat egészítsük ki.
                </li>
                <li>
                  <b>Törlés </b>– Kérhet minket arra, hogy a nálunk tárolt
                  valamennyi személyes adatát töröljük.
                </li>
                <li>
                  <b>A feldolgozás korlátozása</b> – Kérhet minket, hogy
                  bizonyos feltételek esetén korlátozzuk az Ön adataihoz történő
                  hozzáférést.
                </li>
                <li>
                  <b>A feldolgozás elleni tiltakozás</b> – Ön kérhet minket
                  arra, hogy az adait ne használjuk fel semmilyen adatkezelési
                  folyamatban.
                </li>
                <li>
                  <b>Hordozhatóság</b> – Kérheti, hogy tájékoztassuk arrül, hogy
                  az Ön adatai közül melyeket adtuk át egy másik szervezetnek.
                </li>
                <li>
                  Ha a fenti jogaival élni szeretne, lépjen kapcsolatba velünk
                  az alábbi elérhetőségeken. Kérésének teljesítésére maximum 1
                  hónap áll rendelkezésünkre:
                </li>
                <ul>
                  <li>
                    email: <b>kovacsg76@gmail.com</b>
                  </li>
                  <li>telefon: +36-20-9822-266 </li>
                  <li>levélcím: 2053 Herceghalom, Búzavirág utca 22.</li>
                </ul>
                <h2>Cookie-k</h2>A cookie-k olyan szöveges file-ok, amiket az Ön
                számítógépén helyezünk el annak érdekében, hogy leegyszerűstsük
                a bejelentkezéseket és adatokat gyüjthessü a weboldalunk
                látogatásával kapcsolatos szokásairól.
                <h2>Milyen cookie-kat használunk?</h2>
                Az oldalon nem használunk cookie-kat.
                <h2>Más weboldalat adatvédelmi szabályzata</h2>
                Az oldalunk tartalmazhat olyan linkeket, amelyek más
                weboldalakra vezetik Önt át. Jelen adatvédelmi szabályzat csak a
                mi oldalunkra vonatkozik, ezért ha egy másik oldalra tér át,
                olvassa el annak adatvédelmi szabályzatát is.
                <h2>Az adatvédelmi szabályzat változása</h2>
                Társaságunk rendszeresen felülvizsgálja az adatvédelmi
                szabályzatát és szükség esetén módosítja. Jelen adatvédelmi
                szabályzat 2021 November 18-án volt utoljára módosítva.
                <h2>Hogyan veheti fel velünk a kapcsolatot?</h2>
                Ha az adatvédelmi szabályzatunkkal kapcsolatban észrevételei
                lennének, vagy gyakorolni szeretné a fentiekben leírt
                adatvédelmi jogait, kérjük lépjen velün kapcsolatba:
                <ul>
                  <li> kapcsolattartó: Kovács Gabriella</li>
                  <li> email: kovacsg76@gmail.com</li>
                  <li>telefon: +36-20-9822-266</li>
                  <li>postacím: 2053 Herceghalom, Búzavirág utca 22.</li>
                </ul>
                <h2>Hogyan veheti fel a kapcsolatot a hatóságokkal?</h2>
                Ha panaszt szeretne tenni, vagy úgy érzi, hogy társaságunk nem
                kezeli megfelelően az Ön adatait, a Nemzeti Adatvédelmi
                Hatósághoz fordulhat:
                <li>
                  Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH){" "}
                </li>
                <li>Address: 1125 Budapest, Szilágyi Erzsébet fasor 22/C</li>
                <li>PO: 1530 Budapest, Postafiók: 5.</li>
              </div>
            </div>
          )}
        </div>
      </section>
      <Contact />
    </Layout>
  )
}

export default Privacy
